import React, { useState } from 'react';
import { Box, Typography, IconButton, Drawer, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import JudgeSchedule from "./JudgeSchedule";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightJudgeSidebar from "../sidebar/RightJudgeSidebar";
import '../../styles/dashboard.css';
import {Title} from "@mui/icons-material";

export const getName = (userData) => {
    let name = "?";
    if (userData !== null) {
        if (userData.personData !== null && userData.personData !== undefined) {
            if (userData.personData.first_name !== null) {
                name = userData.personData.first_name;
            } else if (userData.username) {
                name = userData.username;
            }
        } else if (userData.username) {
            name = userData.username;
        }
    }
    return name;
}

const JudgeDashboard = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    if (props.userData === null) {
        return (
            <div>error loading user data!</div>
        );
    }

    let name = getName(props.userData);
    let rubrics = [];
    if (props.userData !== null) {
        rubrics = props.userData.rubricData;
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div className="container">
            <IconButton
                className="dashboard-drawer"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ position: 'absolute', top: 10, left: 10 }}
            >
                <div className="dashboard-drawer">
                    <MenuIcon  />
                </div>
            </IconButton>
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightJudgeSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>
            <div className="left-sidebar">
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>
            <div className="main-content">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Title/>
                    <Typography>Your Schedule:</Typography>
                    <JudgeSchedule rubrics={rubrics.rubrics} />
                </Box>
            </div>
            <div className="right-sidebar">
                <RightJudgeSidebar userData={props.userData} setUserData={props.setUserData} />
            </div>
        </div>
    );
};

export default JudgeDashboard;