import React, { useState, useEffect } from 'react';
import {Box, Button, Card, CardContent, CircularProgress, Slider, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";


const RubricCategoryEntry = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.data.score);
    const [selectedLevel, setSelectedLevel] = useState(props.data.score);

    const setValue = (value) => {
        // ensure is integer >0
        if (value < 0 || isNaN(value)) {
            value = 0;
        }
        props.data.score = value;
        props.submitScore(props.index)
        setSelectedValue(value);
    }

    const handleLevelClick = (level) => {
        setSelectedLevel(level);
        setValue(level);
    };
    useEffect(() => {
        if(props.data.score !== null && props.data.score !== undefined)
        {
            if(props.data.score !== 0) {
                setSelectedValue(props.data.score);
                setSelectedLevel(props.data.score);
                setValue(props.data.score);
            }
        }

    }, [props.data.score]);
    if(props.data.score === null)
    {
        return <div></div>
    }



    const LeveledRubric = () => {
        const parseDescription = (description) => {
            const sections = description.split(/(ABOUT:|LEVEL 1:|LEVEL 2:|LEVEL 3:|LEVEL 4:)/).filter(Boolean);
            const parsedSections = {
                about: sections[sections.indexOf("ABOUT:") + 1] || "",
                level1: sections[sections.indexOf("LEVEL 1:") + 1] || "",
                level2: sections[sections.indexOf("LEVEL 2:") + 1] || "",
                level3: sections[sections.indexOf("LEVEL 3:") + 1] || "",
                level4: sections[sections.indexOf("LEVEL 4:") + 1] || "",
            };
            return parsedSections;
        };

        const sections = parseDescription(props.data.description);


        return (
            <div>
                <TableContainer style={{ margin: "10px" }} component={Paper}>
                    <Typography style={{ margin: "10px", textAlign: "center" }}>{sections.about}</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Level</TableCell>
                                <TableCell>Description</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Level 1</TableCell>
                                <TableCell
                                    onClick={() => handleLevelClick(1)}
                                    style={{
                                        backgroundColor: selectedLevel === 1 ? '#1975d0' : '#555555',
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {sections.level1}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Level 2</TableCell>

                                <TableCell
                                    onClick={() => handleLevelClick(2)}
                                    style={{
                                        backgroundColor: selectedLevel === 2 ? '#1975d0' : '#555555',
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {sections.level2}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Level 3</TableCell>

                                <TableCell
                                    onClick={() => handleLevelClick(3)}
                                    style={{
                                        backgroundColor: selectedLevel === 3 ? '#1975d0' : '#555555',
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {sections.level3}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Level 4</TableCell>

                                <TableCell
                                    onClick={() => handleLevelClick(4)}
                                    style={{
                                        backgroundColor: selectedLevel === 4 ? '#1975d0' : '#555555',
                                        color: 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {sections.level4}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    };

    const getNumericButtons = (maxScore) => {
        if (maxScore <= 10) {
            return (
                <div>
                    {Array.from({ length: maxScore }, (_, i) => i + 1).map((score) => (
                        <Button
                            key={score}
                            onClick={() => setValue(score)}
                            style={{
                                margin: '5px',
                                color: 'white',
                                backgroundColor: selectedValue === score ? '#1975d0' : '#555555',
                                opacity: selectedValue === null ? 0.5 : 1,
                            }}
                        >
                            {score}
                        </Button>
                    ))}
                </div>
            );
        } else {
            return (
                <Slider
                    value={selectedValue}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={maxScore}
                    onChange={(e, value) => setValue(value)}  // Update the value state
                    style={{ color: '#1975d0' }}
                />
            );
        }
    };
    const NumericRubric = () => {
        return (
            <div>
            {/*    mui card*/}
                <Card style={{backgroundColor: '#383838', color: 'white', textAlign: "center", margin: '10px'}}>
                    <CardContent>
                        <Typography variant="body1">
                            {props.data.name}
                        </Typography>
                        {getNumericButtons(props.data.max_score)}

                    </CardContent>
                </Card>
            </div>
        )
    }

    if(props.rubric === true)
    {
        return LeveledRubric();
    }
    else
    {
        return NumericRubric();
    }
}

export default RubricCategoryEntry;
