import React, { useState } from 'react';
import { Box, Typography, Card, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateRubricTemplateCategory } from "../../../api/api";

const TemplateEditorCategorySubheading = (props) => {
    const [refresher, setRefresher] = useState(false);
    const [weightInput, setWeightInput] = useState(props.data.weight);
    const [maxScoreInput, setMaxScoreInput] = useState(props.data.max_score);
    const [nameInput, setNameInput] = useState(props.data.name);
    const [levels, setLevels] = useState(props.isRubric ? props.data.description.split("\n").slice(1).map(level => level.split(": ").slice(1).join(": ")) : []);

    const [error, setError] = useState("");
    const [scoreError, setScoreError] = useState("");
    const [nameError, setNameError] = useState("");

    const setWeight = async (weight) => {
        if (weight < 0) {
            setError("Weight must be greater than 0");
            return;
        }
        let changes = { weight: weight };
        const response = await updateRubricTemplateCategory(props.data.id, props.data.rubric_id, changes);
        if (response === null || response === undefined) {
            console.error("Error updating category weight");
            setError("Unknown error updating weight");
            return;
        }
        props.data.weight = weight;
        props.onEdit();
        setError("");
        setRefresher(!refresher);
    };

    const setMaxScore = async (max_score) => {
        if (max_score < 0) {
            setError("Max Score must be greater than 0");
            return;
        }
        let changes = { max_score: max_score };
        const response = await updateRubricTemplateCategory(props.data.id, props.data.rubric_id, changes);
        if (response === null || response === undefined) {
            console.error("Error updating category max score");
            setScoreError("Unknown error updating max score");
            return;
        }
        props.data.max_score = max_score;
        props.onEdit();
        setScoreError("");
        setRefresher(!refresher);
    };

    const setName = async (name) => {
        if (name.length < 1 || name.length > 256) {
            setNameError("Name must have length of 1-256 characters");
            return;
        }

        let changes = { category_name: name };
        const response = await updateRubricTemplateCategory(props.data.id, props.data.rubric_id, changes);
        if (response === null || response === undefined) {
            console.error("Error updating category name");
            setNameError("Unknown error updating category name");
            return;
        }
        props.data.name = name;
        props.onEdit();
        setNameError("");
        setRefresher(!refresher);
    };

    const setDescription = async () => {
        let desc = "ABOUT: " + props.data.name + "\n";
        for (let i = 0; i < levels.length; i++) {
            desc += "Level " + (i + 1) + ": " + levels[i] + "\n";
        }
        let changes = { category_description: desc };
        const response = await updateRubricTemplateCategory(props.data.id, props.data.rubric_id, changes);
        if (response === null || response === undefined) {
            console.error("Error updating category description");
            setNameError("Unknown error updating category description");
            return;
        }
        props.data.description = desc;
        props.onEdit();
        setRefresher(!refresher);
    };

    return (
        <Box>
            <Card sx={{ padding: "10px", margin: "5px", color: "#ffffff", backgroundColor: (props.isRubric) ? "#6d86a1" : "#91809f" }}>
                {(refresher) ? "" : ""}
                <div>
                    <Typography variant="h6">{props.data.name}</Typography>
                    <Typography sx={{ marginTop: "5px" }}>Out of {props.data.max_score} point{(parseInt(props.data.max_score) === 1) ? "" : "s"}</Typography>
                    {(props.isRubric === true) ? <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Accordion sx={{ marginTop: "10px", backgroundColor: (props.isRubric) ? "#506377" : "#605469", color: "white" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
                                    <Typography>Modify</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            <Typography>About:</Typography>
                                            <TextField
                                                type="text"
                                                value={nameInput}
                                                onChange={(e) => {
                                                    if (e.target.value.length >= 256) {
                                                        return;
                                                    }
                                                    setNameInput(e.target.value);
                                                }}
                                                sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf", input: { color: 'white' } }}
                                                InputLabelProps={{
                                                    style: { color: 'white' }
                                                }}
                                            />
                                            <Button variant="contained" sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }} onClick={() => {
                                                setName(nameInput).then(() => {
                                                    setDescription();
                                                })
                                            }}>
                                                Set About
                                            </Button>
                                        </Box>
                                        {[1, 2, 3, 4].map((level) => (
                                            <Box key={level} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                <Typography>Level {level}</Typography>
                                                <TextField multiline
                                                           type="text"
                                                           value={levels[level - 1] || ''}
                                                           onChange={(e) => {
                                                               let newLevels = [...levels];
                                                               newLevels[level - 1] = e.target.value;
                                                               setLevels(newLevels);
                                                           }}
                                                           sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }}
                                                           InputProps={{
                                                               style: { color: 'white' },
                                                               inputProps: { style: { color: 'white' } }
                                                           }}
                                                           InputLabelProps={{
                                                               style: { color: 'white' }
                                                           }}
                                                />
                                                <Button variant="contained" sx={{ width: '100%', backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }} onClick={setDescription}>
                                                    Update Description
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button variant="contained" sx={{ marginTop: "5px", backgroundColor: (props.isRubric) ? "#af8b95" : "#af8b95" }} onClick={() => {
                                            props.deleteSelf(props.data.id);
                                        }
                                        }> Delete </Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        :

                        <Accordion sx={{ marginTop: "10px", backgroundColor: (props.isRubric) ? "#506377" : "#605469", color: "white" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}>
                                <Typography>Modify</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>Category Name:</Typography>
                                        <TextField
                                            type="text"
                                            value={nameInput}
                                            onChange={(e) => {
                                                if (e.target.value.length >= 256) {
                                                    return;
                                                }
                                                setNameInput(e.target.value);
                                            }}
                                            sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf", input: { color: 'white' } }}
                                            InputLabelProps={{
                                                style: { color: 'white' }
                                            }}
                                        />
                                        <Button variant="contained" sx={{ marginTop: "5px", backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }} onClick={() => setName(nameInput)}>
                                            Set Name
                                        </Button>
                                        <Typography sx={{ color: "red", marginLeft: "10px" }}>{nameError}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>Weight:</Typography>
                                        <TextField
                                            type="number"
                                            value={weightInput}
                                            onChange={(e) => {
                                                if (e.target.value <= 0) {
                                                    return;
                                                }
                                                setWeightInput(e.target.value);
                                            }}
                                            sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf", input: { color: 'white' } }}
                                            InputLabelProps={{
                                                style: { color: 'white' }
                                            }}
                                        />
                                        <Button variant="contained" sx={{ marginTop: "5px", backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }} onClick={() => setWeight(weightInput)}>
                                            Set Weight
                                        </Button>
                                        <Typography sx={{ color: "red", marginLeft: "10px" }}>{error}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>Max Score:</Typography>
                                        <TextField
                                            type="number"
                                            value={maxScoreInput}
                                            onChange={(e) => {
                                                if (e.target.value <= 0) {
                                                    return;
                                                }
                                                setMaxScoreInput(e.target.value);
                                            }}
                                            sx={{ backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf", input: { color: 'white' } }}
                                            InputLabelProps={{
                                                style: { color: 'white' }
                                            }}
                                        />
                                        <Button variant="contained" sx={{ marginTop: "5px", backgroundColor: (props.isRubric) ? "#748ea8" : "#a08baf" }} onClick={() => setMaxScore(maxScoreInput)}>
                                            Set Max Score
                                        </Button>
                                        <Typography sx={{ color: "red", marginLeft: "10px" }}>{scoreError}</Typography>
                                    </Box>

                                    <Button variant="contained" sx={{ marginTop: "5px", backgroundColor: (props.isRubric) ? "#af8b95" : "#af8b95" }} onClick={() => {
                                        props.deleteSelf(props.data.id);
                                    }
                                    }> Delete </Button>
                                </Box>
                            </AccordionDetails>
                        </Accordion>}
                </div>
            </Card>
        </Box>
    );
};

export default TemplateEditorCategorySubheading;