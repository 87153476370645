import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography, Drawer, Divider, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightAdminSidebar from "../sidebar/RightAdminSidebar";
import ActionBox from "../reusable/Actionbox";
import Title from "../reusable/Title";

const AdminDashboard = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    let name = props.userData?.personData?.first_name || props.userData?.username || "?";

    const goToManagement = () => navigate('/auth/admin/management');
    const gotoRubricTemplateManagement = () => navigate('/auth/admin/template-management');
    const goToCheckedIn = () => navigate('/auth/admin/management/check-in');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div className="container">
            {isSmallScreen && (
                <IconButton
                    className="dashboard-drawer"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ position: 'absolute', top: 10, left: 10 }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>

            <div className="left-sidebar">
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>

            <div className="main-content">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Title/>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ActionBox
                            title="Registration Management"
                            description="Add, remove, modify Judges, Students, and Projects"
                            onClick={goToManagement}
                        />
                        <ActionBox
                            title="Rubric Template Management"
                            description="Modify your region's rubrics prior to their instantiation."
                            onClick={gotoRubricTemplateManagement}
                        />
                        <ActionBox
                            title="Checked-In"
                            description="View the list of checked-in participants."
                            onClick={goToCheckedIn}
                        />
                        <ActionBox
                            title="View Rubrics"
                            description="View and manage rubrics."
                            onClick={() => {}}
                        />
                    </Box>
                </Box>
            </div>

            <div className="right-sidebar">
                <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
            </div>
        </div>
    );
};

export default AdminDashboard;