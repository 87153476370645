import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ErrorContext = createContext(null);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ErrorProvider = ({ children }) => {
  const [message, setMessage] = useState({ open: false, text: '', severity: 'info' });

  const showMessage = (text, severity = 'info') => {
    setMessage({ open: true, text, severity });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({ open: false, text: '', severity: 'info' });
  };

  return (
    <ErrorContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={message.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleClose} severity={message.severity} sx={{ width: '100%' }}>
          {message.text}
        </Alert>
      </Snackbar>
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
