import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
      ? '/api/v1/'
      : 'http://localhost:8086/v1/',
});
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
