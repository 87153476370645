import React from 'react';
import {Box, Button, createTheme, ThemeProvider, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";

const RightJudgeSidebar = (props) => {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
        },
    });
    const navigate = useNavigate();

    const handleRubricClick = (projectId) => {
        navigate('/auth/rubric/'+ projectId,);
    };

    const shortenText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    let toDoRubrics = props.userData.rubricData.rubrics.filter((rubric) => rubric.completed === 0)
    return (
        <Box
            sx={{
                backgroundColor: '#f0f0f0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                borderLeft: '1px solid #ccc',
            }}
        >
            <Typography variant="h6" sx={{ mb: 2 }}>
                Judge Dashboard
            </Typography>
            <Typography>Incomplete Evaluations:</Typography>
            <ThemeProvider theme={theme}>
            {toDoRubrics.map((rubric) => {
                return (
                    <Button style={{width:'90%'}} key={rubric.project_id} variant="contained" sx={{ mb: 1 }} onClick={() => handleRubricClick(rubric.project_id)}>{shortenText(rubric.project_name, 20)}</Button>
                )
            })}
            </ThemeProvider>
        </Box>
    );
}

export default RightJudgeSidebar;