import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useError } from '../../context/ErrorContext';
import useAuth from "../../hooks/useAuth";

const SignOutButton = (props) => {
  const navigate = useNavigate();
  const { showMessage } = useError();
  const { setIsAuthenticated } = useAuth();

  const handleSignOut = () => {


    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    props.setUserData(null)
    setIsAuthenticated(false)
    showMessage('You have been signed out.', 'info');
    navigate('/login', { state: { message: 'You are now logged out', severity: 'success' } });
  };
  let sx = props.sx;
  if (sx === undefined || sx === null) {
    sx = { mb: 1 };
  }

  let style = props.style;
  if (style === undefined || style === null) {
    style = {}
  }

  return (
    <Button style={style} variant="contained" sx={sx} color="primary" onClick={handleSignOut}>
      Sign Out
    </Button>
  );
};

export default SignOutButton;