import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {TextField, Button, Container, Typography, Box, InputLabel, Select, FormControl, MenuItem} from '@mui/material';
import { signup } from '../../api/auth';
import { get_regions} from "../../api/api";
import { useError } from '../../context/ErrorContext';
const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [region, setRegion] = useState('');
  const [regions, setRegions] = useState([]);
  const navigate = useNavigate();
  const { showMessage } = useError();
  useEffect(() => {
    const loadRegions = async () => {
      try {
        const regionsList = await get_regions();
        setRegions(regionsList);
      } catch (error) {
        console.error('Error loading regions:', error);
        showMessage('Error loading regions', 'error');
	setRegions([]);
      }
    }

    loadRegions();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        if (password !== confirmPassword) {
            throw new Error('Passwords do not match');
        }
      await signup({ username, password, email }, region);
      navigate('/login', { state: { message: 'Sign-up successful! Please log in.', severity: 'success' } });
    } catch (error) {
      console.log(error)
      showMessage(error.message, 'error');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm_password"
            label="Confirm Password"
            type="password"
            id="confirm_password"
            autoComplete="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region"
              value={region}
              label="Region"
              onChange={(e) => setRegion(e.target.value)}
            >
              {regions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
        <Box>
          <Typography>Already signed up?</Typography>
          <Button
            onClick={() => navigate('/login')}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUpForm;
