import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import {getUserData, getPersonData, loadUserDataComplete} from '../../api/api';

const ReloadDataButton = (props) => {
    const navigate = useNavigate();

    const navigateToDashboard = async () => {
        navigate('/auth/dashboard');
    };
    let style = props.style;
    if (style === undefined || style === null) {
        style = {}
    }

    return (
        <Button style={style} variant="contained" sx={{mb:1}} color="primary" onClick={navigateToDashboard}>
            Dashboard
        </Button>
    );
};

export default ReloadDataButton;