import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';



export default function IconSwitch(props) {

    let { iconBefore, iconAfter, thumb, rail, border, defaultCheckedValue, onChangeHook} = props;

    if (thumb === undefined) {
        thumb = '#000000';
    }
    if (rail === undefined) {
        rail = '#FFFFFF';
    }
    if (border === undefined) {
        border = '#000000';
    }

    if(defaultCheckedValue === undefined){
        defaultCheckedValue = true;
    }

    if (onChangeHook === undefined) {
        onChangeHook = () => { return true };
    }

    const [checked, setChecked] = React.useState(defaultCheckedValue);



    const BaseSwitch = styled(Switch)({
        width: 45,
        height: 22,
        padding: 0,
        margin: "2px",
        scale: "150%",
        display: 'flex',
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(23px)',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: rail
                }
            }
        },
        '& .MuiSwitch-thumb': {
            width: 18,
            height: 18,
            borderRadius: '25px',
            backgroundColor: thumb
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            borderRadius: '25px',
            border: `1px solid ${border}`,
            backgroundColor: rail,
            boxSizing: 'border-box'
        }
    });

    const convertSvg = (svg) => {
        const markup = renderToStaticMarkup(svg);
        const encoded = encodeURIComponent(markup);
        return `url('data:image/svg+xml;utf8,${encoded}')`;
    };

    const SwitchWithIcons = styled(BaseSwitch)({
        '& .MuiSwitch-track': {
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
                top: 11
            },
            '&:before': {
                backgroundImage: convertSvg(iconBefore),
                left: 3
            },
            '&:after': {
                backgroundImage: convertSvg(iconAfter),
                right: 3
            }
        },
        '& .MuiSwitch-thumb:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: convertSvg(iconBefore)
        },
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    content: "''",
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: convertSvg(iconAfter)
                }
            }
        }
    });

    return <SwitchWithIcons checked={checked} onChange={ async () => {
        const result = await onChangeHook(!checked)
        if(result === true)
            setChecked(!checked);
        else
            setChecked(checked);
    }} />;
}