import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getName } from "../../judge/JudgeDashboard";
import LeftSidebar from "../../sidebar/LeftSidebar";
import {
    createRubricTemplateCategory,
    deleteRubricTemplateCategory,
    getTemplateDetails,
    loadUserDataComplete
} from "../../../api/api";
import RightAdminSidebar from "../../sidebar/RightAdminSidebar";
import { Box, Button, Divider, Drawer, IconButton, Typography, Fab, Tooltip, Dialog, DialogTitle, DialogContent, Tabs, Tab, TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from '@mui/icons-material/Add';
import TemplateEditorCategoryHeader from "./TemplateEditorCategoryHeader";
import '../../../styles/dashboard.css';
import Title from "../../reusable/Title";

const TemplateEditorView = (props) => {
    let name = getName(props.userData);
    const [loadedData, setLoadedData] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [template, setTemplate] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const url = window.location.href;
    const template_id = url.substring(url.lastIndexOf('/') + 1);

    const navigate = useNavigate();

    if (props.userData === null || props.userData === undefined) {
        if (loadedData === false) {
            setLoadedData(true);
            loadUserDataComplete(props.setUserData);
        }

        return (
            <div>
                <h1>Rubric Template Management</h1>
                <p>Loading...</p>
                <p>If you are not logged in you should be redirected, you will not be able to access this page logged out.</p>
            </div>
        );
    } else {
        if (loadedData === false) {
            setLoadedData(true);
            getTemplateDetails(template_id, setTemplate);
        }
    }

    if (props.userData.adminData === null || props.userData.adminData === undefined) {
        return (
            <div>
                <h1>Error Loading Administrator Data</h1>
                <p>This happens if you were recently promoted to Admin or have missing local data. Try refreshing...</p>
            </div>
        );
    }

    if (template === null) {
        return (
            <div>
                Attempting to load template with id {template_id}...
            </div>
        );
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleDialogOpen = () => {
        const rubricExists = Object.values(template.categories).some(category => category.name === "Rubric");
        setTabIndex(rubricExists ? 0 : tabIndex);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleCategoryNameChange = (event) => {
        const value = event.target.value;
        if (/^[a-zA-Z0-9\s\-()?!,.%&]*$/.test(value) && value.length <= 256) {
            setCategoryName(value);
        }
    };

    const addTemplateCategory = async() => {
        if (categoryName === "Rubric") {
            setErrorMessage("The name 'Rubric' is invalid for a category.");
            return;
        }
        else if (categoryName === "") {
            setErrorMessage("Please enter a name for the category.");
            return;
        }

        const result = await createRubricTemplateCategory(template_id, categoryName);
        if (result === null || result === undefined) {
            console.error("Error creating category");
            return
        }

        if(result.id === null || result.id === undefined) {
            console.error("Error creating category (no gen id)");
            return
        }

        let generated_id = result.id;
        let newCategory = {
            id: generated_id,
            name: categoryName,
            parent_category_id: null,
            rubric_id: parseInt(template_id),
            description: "",
            weight: 0,
            max_score: 0,
            children: {}
        }

        // console.log(newCategory)

        let newTemplate = {...template};
        newTemplate.categories[generated_id] = newCategory;
        handleDialogClose();
    };

    const addRubricCategory = async() => {

        const result = await createRubricTemplateCategory(template_id, "Rubric");
        if (result === null || result === undefined) {
            console.error("Error creating category");
            return
        }

        if(result.id === null || result.id === undefined) {
            console.error("Error creating category (no gen id)");
            return
        }

        let generated_id = result.id;
        let newCategory = {
            id: generated_id,
            name: "Rubric",
            parent_category_id: null,
            rubric_id: template_id,
            description: "",
            weight: 0,
            max_score: 0,
            children: {}
        }

        let newTemplate = {...template};
        newTemplate.categories[generated_id] = newCategory;
        handleDialogClose();
    };

    const deleteCategory = async (category_id) => {
        const result = await deleteRubricTemplateCategory(category_id, template_id)
        if (result === null || result === undefined) {
            console.error("Error deleting category");
            return
        }

        if (result === false) {
            console.error("Error deleting category (false)");
            return
        }

        let newTemplate = {...template};

        delete newTemplate.categories[category_id];
        setTemplate(newTemplate);

    }

    const rubricExists = Object.values(template.categories).some(category => category.name === "Rubric");

    return (
        <div className="container">
            <IconButton
                className="dashboard-drawer"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ position: 'absolute', top: 10, left: 10 }}
            >
                <div className="dashboard-drawer">
                    <MenuIcon />
                </div>
            </IconButton>
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>

            <div className="left-sidebar">
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>

            <div className="main-content">
                <Title/>
                <Box>
                <Typography variant="h6">Editing: </Typography>
                <Typography variant="h4">{template.template.name}</Typography>
                <Button sx={{marginTop: "10px", marginBottom: "10px"}} variant="contained"
                        onClick={() => navigate('/auth/admin/template-management')}>Back to template manager</Button>

                {Object.entries(template.categories)
                    .sort((a, b) => a[1].name === "Rubric" ? 1 : b[1].name === "Rubric" ? -1 : 0)
                    .map((category) => (
                        <TemplateEditorCategoryHeader
                            isRubric={category[1].name === "Rubric"}
                            deleteSelf={deleteCategory}
                            key={category[0]}
                            data={category[1]}
                        />
                    ))
                }

                <Typography
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: '#444444',
                        marginTop: "10px",
                        marginBottom: '50px'
                    }}
                    variant="h7"
                >
                    All changes are automatically saved
                </Typography>

                <Tooltip title="Add New...">
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            margin: "15px"
                        }}
                        onClick={handleDialogOpen}
                    >
                        <AddIcon/>
                    </Fab>
                </Tooltip>

                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Add New</DialogTitle>
                    <DialogContent>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label="Criterion"/>
                            <Tab label="Rubric" disabled={rubricExists}/>

                        </Tabs>
                        {tabIndex === 0 && (
                            <Box>
                                <Typography sx={{paddingTop: "5px"}} variant="h6">Add a criterion</Typography>
                                <Typography variant="body" sx={{color: "#525252"}}>
                                    Criteria have numerical scores (i.e 1-10) based on how well a project achieves an
                                    aspect of the rubric.
                                </Typography>
                                <TextField
                                    label="Criterion Name"
                                    value={categoryName}
                                    onChange={handleCategoryNameChange}
                                    fullWidth
                                    margin="normal"
                                />
                                {errorMessage && (
                                    <Typography color="error" variant="body2">
                                        {errorMessage}
                                    </Typography>
                                )}
                                <Button variant="contained" color="primary" onClick={addTemplateCategory}>
                                    Add Criterion
                                </Button>
                            </Box>
                        )}
                        {tabIndex === 1 && (
                            <Box>
                                <Box>
                                    <Typography sx={{paddingTop: "5px"}} variant="h6">Add a rubric</Typography>
                                    <Typography variant="body" sx={{color: "#525252"}}>
                                        Rubrics are leveled scores (i.e 1-4) based on how well a project achieves an
                                        aspect of the rubric.
                                    </Typography>
                                </Box>
                                <Button sx={{marginTop: "10px"}} variant="contained" color="primary"
                                        onClick={addRubricCategory}>
                                    Add Rubric
                                </Button>
                            </Box>
                        )}
                    </DialogContent>
                </Dialog>
            </Box>
            </div>

            <div className="right-sidebar">
                <RightAdminSidebar name={name} userData={props.userData} setUserData={props.setUserData}/>
            </div>
        </div>
    );
};


export default TemplateEditorView;