import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {AuthProvider} from './context/AuthContext';
import App from './App';
import {ErrorProvider} from "./context/ErrorContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <ErrorProvider>
          <App />
      </ErrorProvider>
    </AuthProvider>
  // </React.StrictMode>
  );