import React from 'react';
import {Box, Button, createTheme, ThemeProvider, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";

const RightAdminSidebar = (props) => {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
        },
    });
    const navigate = useNavigate();

    const shortenText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    return (
        <Box
            sx={{
                backgroundColor: '#f0f0f0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                border: '5px solid #ccc',
                borderColor: '#989898',
            }}
        >
            <Typography variant="h6" sx={{ mb: 2 }}>
                Admin Dashboard
                <Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} onClick={() => navigate("/auth/admin/management")}>{"Registration Management"}</Button>
                <Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} onClick={() => navigate("/auth/admin/template-management")}>{"Template Management"}</Button>
                <Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} onClick={() => navigate("/auth/admin/management/check-in")}>{"Checked In Students"}</Button>
                <Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} onClick={() => navigate("/auth/admin/dashboard")}>{"Completed Evals"}</Button>
                <Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} onClick={() => navigate("/auth/admin/dashboard")}>{"Active Rubrics"}</Button>

            </Typography>
        </Box>
    );
}

export default RightAdminSidebar;