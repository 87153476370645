import React from 'react';
import {useNavigate} from "react-router-dom";

import useAuth from "../hooks/useAuth";


import { AppBar, Toolbar, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
const Home = () => {
  const navigate = useNavigate();
    let year = new Date().getFullYear();
    const { setIsAuthenticated, isAuthenticated } = useAuth();

    return (
        <div style={styles.container}>
            {/* Navigation Bar */}
            <AppBar position="static">
                <Toolbar style={styles.toolbar}>
                    <Typography variant="h6" style={styles.logo}>
                        PRSF {year}
                    </Typography>
                    {/*<div style={styles.navButtons}>*/}
                    {/*    <Button color="inherit" onClick={() => navigate('/about')}>*/}
                    {/*        About*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </Toolbar>
            </AppBar>

            {/* Main Content */}
            <Card style={styles.card}>
                <CardContent>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Peel Regional Science Fair {year}
                    </Typography>
                    {(isAuthenticated)? (<Typography variant="body1" color="textSecondary">You're already logged in.</Typography>):
                        (<Typography variant="body1" color="textSecondary" gutterBottom>
                        If you are a judge, login with the credentials you were provided:
                    </Typography>)}

                </CardContent>
                <CardActions style={styles.buttonGroup}>
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    onClick={() => navigate('/signup')}*/}
                    {/*>*/}
                    {/*    Sign Up*/}
                    {/*</Button>*/}
                    {(isAuthenticated)?<Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/auth/dashboard')}
                    >
                        Dashboard
                    </Button>
                        :
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/login')}
                    >
                        Log In
                    </Button>}
                </CardActions>
            </Card>
            {/* Footer */}
            <footer style={styles.footer}>
                <p>&copy; 2024 University of Toronto Mississauga. Maybe some rights reserved.</p>
            </footer>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '100vh',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
        paddingBottom: '10px',
    },
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
        backgroundColor: '#3f51b5',
        color: 'white',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    },
    logo: {
        marginLeft: '20px',
        fontSize: '24px',
    },
    navButton: {
        color: 'white',
        marginRight: '20px',
    },
    card: {
        margin: '50px',
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        textAlign: 'center',
        maxWidth: '400px',
        width: '100%',
    },
    title: {
        fontSize: '36px',
        marginBottom: '20px',
        color: '#3f51b5',
    },
    subtitle: {
        fontSize: '16px',
        marginBottom: '40px',
        color: '#666',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
    },
    button: {
        width: '120px',
        padding: '10px',
    },
    footer: {
        padding: '10px',
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#3f51b5',
        color: 'white',
        marginTop: 'auto',
    },
};


export default Home;