import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ActionBox = ({ title, description, onClick }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
            margin: 1,
            backgroundColor: '#f0f0f0',
            borderRadius: 2,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            width: '100%',
            maxWidth: '300px',
            textAlign: 'center'
        }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>{title}</Typography>
            <Typography variant="body2" sx={{ marginBottom: 2 }}>{description}</Typography>
            <Button variant="contained" onClick={onClick}>{title}</Button>
        </Box>
    );
};

export default ActionBox;