import React, { useState, useMemo } from 'react';
import {
    Box,
    Button,
    IconButton,
    Tabs,
    Tab,
    TextField,
    Typography,
    Drawer,
    Divider,
    useTheme,
    useMediaQuery,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { Menu as MenuIcon, CheckCircle, Cancel } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightAdminSidebar from "../sidebar/RightAdminSidebar";
import { parseData } from "../../api/api"; // Assuming parseData is an API call
import { v4 as uuidv4 } from 'uuid';
import Title from "../reusable/Title"; // Importing UUID

const BulkAddPage = (props) => {
    // Drawer state
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const name = props.userData?.personData?.first_name || props.userData?.username || "?";

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    // Tab and Input states
    const [tabValue, setTabValue] = useState(0); // 0 for People, 1 for Projects
    const [inputMethod, setInputMethod] = useState('file'); // 'file' or 'text'
    const [fileContent, setFileContent] = useState(null);
    const [textInput, setTextInput] = useState('');
    const [interpretAsJudge, setInterpretAsJudge] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [fileError, setFileError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    // Handle File Upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
            const allowedExtensions = ['.csv', '.xlsx', '.xls'];

            if (!allowedExtensions.includes(fileExtension)) {
                setFileError('Invalid file type. Please upload a .csv, .xlsx, or .xls file.');
                setFileContent(null);
                return;
            } else {
                setFileError(''); // Clear any previous error
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                const fileData = event.target.result;
                if (fileExtension === '.csv') {
                    // For CSV, save contents as string
                    setFileContent(fileData);
                } else if (fileExtension === '.xlsx' || fileExtension === '.xls') {
                    // For Excel, save base64 as string
                    const base64String = fileData.split(',')[1]; // Remove data:...;base64,
                    setFileContent(base64String);
                }
            };
            if (fileExtension === '.csv') {
                reader.readAsText(file);
            } else if (fileExtension === '.xlsx' || fileExtension === '.xls') {
                reader.readAsDataURL(file);
            }
        }
    };

    // Handle Upload Button Click
    const handleUpload = () => {
        // Check if fileContent or textInput is provided
        if ((inputMethod === 'file' && !fileContent) || (inputMethod === 'text' && !textInput.trim())) {
            setFileError('Please provide valid input before proceeding.');
            return;
        }
        setFileError(''); // Clear any previous error
        setIsLoading(true);
        parse();
    };

    // Parse Function
    const parse = async () => {
        const determineFileType = (content) => {
            if (inputMethod !== 'file') return 'csv';
            return content.startsWith('UEsD') ? 'xlsx' : 'csv';
        };

        let data = {
            load_people: tabValue === 0,
            load_projects: tabValue === 1,
            are_judges: interpretAsJudge,
            person_file_data: tabValue === 0 ? (inputMethod === 'file' ? fileContent : textInput) : null,
            project_file_data: tabValue === 1 ? (inputMethod === 'file' ? fileContent : textInput) : null,
            person_file_type: tabValue === 0 ? determineFileType(fileContent) : 'csv',
            project_file_type: tabValue === 1 ? determineFileType(fileContent) : 'csv',
        };

        try {
            const res = await parseData(data);

            if (!res || !res.success) {
                setDialogOpen(true);
                setIsLoading(false);
                setFileError('');
            } else {
                setIsLoading(false);
                setFileError('');

                let combinedData = [];

                const processPeople = (items, successValue) => {
                    return items.map((item) => ({
                        ...item,
                        success: successValue,
                        id: uuidv4(),
                    }));
                };

                const processProjects = (items, successValue) => {
                    return items.map((item) => ({
                        ...item,
                        success: successValue,
                        id: uuidv4(),
                    }));
                };

                if (tabValue === 0) {
                    if (res.judges && res.judges.length > 0) {
                        combinedData = combinedData.concat(processPeople(res.judges, true));
                    }
                    if (res.failed_judges && res.failed_judges.length > 0) {
                        combinedData = combinedData.concat(processPeople(res.failed_judges, false));
                    }
                    if (res.students && res.students.length > 0) {
                        combinedData = combinedData.concat(processPeople(res.students, true));
                    }
                    if (res.failed_students && res.failed_students.length > 0) {
                        combinedData = combinedData.concat(processPeople(res.failed_students, false));
                    }
                    if (res.misformatted_people && res.misformatted_people.length > 0) {
                        combinedData = combinedData.concat(processPeople(res.misformatted_people, false));
                    }
                } else if (tabValue === 1) {
                    if (res.projects && res.projects.length > 0) {
                        combinedData = combinedData.concat(processProjects(res.projects, true));
                    }
                    if (res.failed_projects && res.failed_projects.length > 0) {
                        combinedData = combinedData.concat(processProjects(res.failed_projects, false));
                    }
                }

                setTableData(combinedData);
                setShowTable(true);
            }
        } catch (error) {
            console.error(error);
            setDialogOpen(true);
            setIsLoading(false);
            setFileError('');
        }
    };

    // Handle Submit More Data Button
    const handleSubmitMoreData = () => {
        setFileContent(null);
        setTextInput('');
        setShowTable(false);
        setTableData([]);
        setFileError('');
    };

    // Column Overrides
    const columnOverrides = {
        first_name: { headerName: 'First Name' }, // Renaming "first_name" to "First Name"
        last_name: { headerName: 'Last Name' },   // Renaming "last_name" to "Last Name"
        email: { headerName: 'Email Address' },   // Renaming "email" to "Email Address"
        students: {
            headerName: 'Students',
            renderCell: (params) => {
                if (Array.isArray(params.value) && params.value.length > 0) {
                    return (
                        <Box
                            sx={{
                                height: '80px', // Fixed height less than rowHeight
                                overflowY: 'auto',
                                pr: 1, // Padding for scrollbar visibility
                                whiteSpace: 'normal', // Allow text to wrap
                                wordWrap: 'break-word', // Break long words
                            }}
                        >
                            {params.value.map((student, index) => (
                                <Box key={student.id || uuidv4()} sx={{ mb: 1 }}>
                                    <Typography variant="body2" component="div">
                                        <strong>{student.first_name} {student.last_name}</strong> (ID: {student.id})
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    );
                }
                return <Typography variant="body2">No Students</Typography>;
            }
        },
        description: {
            headerName: 'Description',
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            height: '80px',
                            overflowY: 'auto',
                            pr: 1,
                            whiteSpace: 'normal', // Allow text to wrap
                            wordWrap: 'break-word', // Break long words
                        }}
                    >
                        <Typography variant="body2">
                            {params.value}
                        </Typography>
                    </Box>
                );
            }
        },
        // To hide a column, you can add a 'hide' property:
        // some_field_to_hide: { hide: true }, // Example of hiding a column
    };

    // Memoized Columns based on tableData and columnOverrides
    const columns = useMemo(() => {
        if (tableData.length === 0) return [];

        const dataFields = Object.keys(tableData[0]).filter(
            (key) => key !== 'id' && key !== 'success'
        );

        return [
            {
                field: 'success',
                headerName: 'Success',
                width: 100,
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {params.value ? <CheckCircle color="success" /> : <Cancel color="error" />}
                    </Box>
                ),
            },
            ...dataFields.map((field) => {
                if (columnOverrides[field]?.hide) return null; // Hide the column if specified

                return {
                    field,
                    headerName: columnOverrides[field]?.headerName ||
                        field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' '),
                    flex: 1,
                    renderCell: columnOverrides[field]?.renderCell || undefined,
                };
            }).filter(Boolean), // Remove nulls for hidden columns
        ];
    }, [tableData, columnOverrides]);

    // Render Tab Content
    const renderTabContent = (showCheckbox) => {
        return (
            <Box sx={{ mt: 2 }}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Input Method</FormLabel>
                    <RadioGroup
                        row
                        value={inputMethod}
                        onChange={(e) => setInputMethod(e.target.value)}
                    >
                        <FormControlLabel value="file" control={<Radio />} label="Upload File" />
                        <FormControlLabel value="text" control={<Radio />} label="Text Input" />
                    </RadioGroup>
                </FormControl>

                {inputMethod === 'file' ? (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            type="file"
                            inputProps={{ accept: '.csv,.xlsx,.xls' }}
                            onChange={handleFileUpload}
                            fullWidth
                        />
                        {fileError && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {fileError}
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <TextField
                        label="Text Input"
                        multiline
                        rows={4}
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                )}

                {showCheckbox && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interpretAsJudge}
                                onChange={(e) => setInterpretAsJudge(e.target.checked)}
                            />
                        }
                        label="Interpret all as Judge"
                        sx={{ mt: 2 }}
                    />
                )}

                <Typography variant="body2" sx={{ mt: 2 }}>
                    Large datasets could take minute(s) to process. You may leave this page, but stay to receive a confirmation.
                </Typography>

                <Box sx={{ position: 'relative', display: 'inline-flex', mt: 2 }}>
                    <Button variant="contained" onClick={handleUpload} disabled={isLoading}>
                        Upload
                    </Button>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <div className="container" style={{ display: 'flex', height: '100vh' }}>
            {/* Drawer for Mobile */}
            {isSmallScreen && (
                <IconButton
                    className="dashboard-drawer"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ position: 'absolute', top: 10, left: 10 }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>

            {/* Left Sidebar for Desktop */}
            <div className="left-sidebar" style={{ flex: '0 0 200px', overflowY: 'auto' }}>
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>

            {/* Main Content */}
            <div className="main-content" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <Title/>

                {/* Title and Description */}
                <div style={{ marginBottom: "15px", padding: '0 16px' }}>
                    <Typography variant="h3">Bulk Add</Typography>
                    <Typography variant="body1" sx={{ fontSize: "15px" }}>
                        Add Judges, Students, and Projects from common data formats.
                    </Typography>
                </div>

                {/* Tabs and Content */}
                <Box sx={{ flex: '1 1 auto', width: '100%', p: 2, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <Tabs
                        value={tabValue}
                        onChange={(event, newValue) => setTabValue(newValue)}
                        centered
                        disabled={showTable} // Disable tabs when showing table
                    >
                        <Tab label="People" />
                        <Tab label="Projects" />
                    </Tabs>

                    {/* Conditional Rendering based on showTable */}
                    {!showTable && (
                        <Box sx={{ flex: '1 1 auto', overflow: 'auto' }}>
                            {tabValue === 0 && renderTabContent(true)}
                            {tabValue === 1 && renderTabContent(false)}
                        </Box>
                    )}

                    {showTable && (
                        <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', pb: 2 }}>
                            <Box sx={{ flex: '1 1 auto', width: '100%', mt: 2 }}>
                                <DataGrid
                                    rows={tableData}
                                    columns={columns}
                                    pageSize={10} // Increased page size for better visibility
                                    rowsPerPageOptions={[10, 25, 50]}
                                    rowHeight={120} // Increased row height
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        '& .MuiDataGrid-cell': {
                                            display: 'block', // Allow wrapping
                                            overflow: 'hidden',
                                            whiteSpace: 'normal', // Allow text to wrap
                                            wordWrap: 'break-word', // Break long words
                                        },
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                onClick={handleSubmitMoreData}
                                sx={{
                                    mt: 2,
                                    alignSelf: 'flex-start',
                                    marginBottom: "20px" // Added margin at the bottom
                                }}
                            >
                                Submit more data
                            </Button>
                        </Box>
                    )}
                </Box>
            </div>

            {/* Right Sidebar for Desktop */}
            <div className="right-sidebar" style={{ flex: '0 0 200px', overflowY: 'auto' }}>
                <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
            </div>

            {/* Error Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    handleSubmitMoreData();
                }}
            >
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your data could not be processed (likely invalid format).
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            handleSubmitMoreData();
                        }}
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

export default BulkAddPage;
