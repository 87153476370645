import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import SignOutButton from "./SignOutButton";
import ReloadDataButton from "./ReloadDataButton";
import DashboardButton from "./DashboardButton";
const LeftSidebar = (props) => {

    return (
        <Box
            sx={{
                height: '100%',
                width:'100%',
                display:'flex',
                backgroundColor: '#f4f4f4',
                flexDirection: 'column',
                padding: '5px',
                border: '5px solid #ccc',
                borderLeft: 'none',
                borderColor: '#989898',
            }}
        >
            <Typography className="truncateWrap" variant="h6" sx={{ mb: 2 }}>
                Hello, {props.name}.
            </Typography>
            <DashboardButton style={{width: '90%'}} sx={{ mb: 1 }}/>
            <ReloadDataButton style={{width:'90%'}} setUserData={props.setUserData}/>
            <SignOutButton style={{width:'90%'}} variant="contained" sx={{ mb: 1 }} setUserData={props.setUserData}/>
            {/*<Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }}>Item 3</Button>*/}
            {/*<Button style={{width:'90%'}} variant="contained" sx={{ mb: 1 }}>Item 4</Button>*/}
        </Box>
    );
}

export default LeftSidebar;