import React from 'react';
import {Box, Typography} from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Title = () => {
    let year = new Date().getFullYear();
    const navigate = useNavigate();

    return (
        <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            <Box onClick={() => {
                navigate('/auth/dashboard');
            }} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <img src={"/prsflogo.png"} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Typography variant="h4">Peel Region Science Fair, {year}</Typography>
            </Box>
        </Box>
  );
};

export default Title;