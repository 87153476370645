import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getName } from "../judge/JudgeDashboard";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightAdminSidebar from "../sidebar/RightAdminSidebar";
import { Box, Button, Divider, Drawer, IconButton, Typography, CircularProgress, Card, CardContent, Switch, TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import '../../styles/dashboard.css';
import Title from "../reusable/Title";
import { fetchStudents, checkIn } from "../../api/api";

const CheckInPage = (props) => {
    let name = getName(props.userData);
    const [loadedData, setLoadedData] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nameSearch, setNameSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');

    useEffect(() => {
        const loadStudents = async () => {
            const response = await fetchStudents(-1, 0);
            if (response) {
                setStudents(response);
            }
            setLoading(false);
        };
        loadStudents();
    }, []);

    const handleNameSearchChange = (event) => {
        setNameSearch(event.target.value);
    };

    const handleEmailSearchChange = (event) => {
        setEmailSearch(event.target.value);
    };

    const handleCheckInToggle = async (checkedIn, studentID) => {
        const res = await checkIn(checkedIn, studentID);
        if (res === null)
            return


        if (res === true) {
            setStudents((prevStudents) =>
                prevStudents.map((student) =>
                    student.id === studentID ? { ...student, checked_in: (checkedIn)? 1:0 } : student
                )
            );
        }
    };

    const filterStudents = () => {
        return students.filter((student) => {
            const fullName = `${student.first_name} ${student.last_name}`.toLowerCase();
            const email = student.email.toLowerCase();
            return (
                fullName.includes(nameSearch.toLowerCase()) &&
                email.includes(emailSearch.toLowerCase())
            );
        });
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div className="container">
            <IconButton
                className="dashboard-drawer"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ position: 'absolute', top: 10, left: 10 }}
            >
                <div className="dashboard-drawer">
                    <MenuIcon />
                </div>
            </IconButton>
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>

            <div className="left-sidebar">
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>

            <div className="main-content">
                <Title/>
                <div style={{marginBottom: "15px", padding: '0 16px'}}>
                    <Typography variant="h3">Check In</Typography>
                    <Typography variant="body1" sx={{fontSize: "15px"}}>
                        Check In students for the science fair.
                    </Typography>
                </div>
                <Box style={{alignItems:"center", alignContent:"center", textAlign:"center"}}>
                    <Typography variant="h4">Search Student:</Typography>
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        <TextField
                            label="Search by Name"
                            variant="outlined"
                            value={nameSearch}
                            onChange={handleNameSearchChange}
                            fullWidth
                        />
                        <TextField
                            label="Search by Email"
                            variant="outlined"
                            value={emailSearch}
                            onChange={handleEmailSearchChange}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        {filterStudents().map((student) => (
                            <Card key={student.id} sx={{ marginBottom: 2, color: "black",
                                backgroundColor: (student.checked_in === 1)? "#a7eeb0" : "#e8a6a6"}}>
                                <CardContent>
                                    <Typography variant="h6">
                                        {student.first_name} {student.last_name}
                                    </Typography>
                                    <Typography variant="body2">{student.email}</Typography>
                                    <Switch
                                        checked={(student.checked_in === 1)}
                                        onChange={(event) => handleCheckInToggle(event.target.checked, student.id)}
                                        color="primary"
                                    />
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </div>

            <div className="right-sidebar">
                <RightAdminSidebar name={name} userData={props.userData} setUserData={props.setUserData}/>
            </div>
        </div>
    );
};

export default CheckInPage;