import React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import '../../styles/dashboard.css';

function createDataFull(project_name, project_id, age_group, student_names, done_status) {
    let names = "";
    student_names.forEach((student) => {
        names += student.first_name + " " + student.last_name + ", ";
    });
    names = names.slice(0, -2);
    return { project_name, project_id, age_group, names, done_status };
}

function createDataCompact(project_name, project_id, done_status) {
    return { project_name, project_id, done_status };
}

const JudgeSchedule = (props) => {
    const rowsFull = [];
    const rowsCompact = [];
    let validSchedule = true;
    let message = "";
    if (props.rubrics !== null && props.rubrics !== undefined) {
        if (props.rubrics.length > 0) {
            validSchedule = true;
            props.rubrics.forEach((rubric) => {
                let team_members = rubric.team_members;
                let age_group = "";
                if (team_members.length > 0) {
                    age_group = team_members[0].age_group;
                    rowsFull.push(createDataFull(rubric.project_name, rubric.project_id, age_group, rubric.team_members, (rubric.completed === 1) ? "Complete" : "Incomplete"));
                    rowsCompact.push(createDataCompact(rubric.project_name, rubric.project_id, (rubric.completed === 1) ? "Complete" : "Incomplete"));
                } else {
                    validSchedule = false;
                    message = "You have no scheduled entries to judge for project " + rubric.project_id;
                }
            });
        } else {
            validSchedule = false;
            message = "You have no scheduled entries to judge!";
        }
    }
    const navigate = useNavigate();

    const handleRowClick = (projectId) => {
        navigate('/auth/rubric/' + projectId);
    };

    return (
        (props.rubrics === null || props.rubrics === undefined) ? <p>Loading...</p> :
            (validSchedule) ? (
                <>
                    <TableContainer component={Paper} className="table-full">
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Project Name</b></TableCell>
                                    <TableCell align="right"><b>Project ID</b></TableCell>
                                    <TableCell align="right"><b>Age Group</b></TableCell>
                                    <TableCell align="right"><b>Student Name(s)</b></TableCell>
                                    <TableCell align="right"><b>Status</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsFull.map((row) => (
                                    <TableRow
                                        key={row.project_name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => handleRowClick(row.project_id)}
                                    >
                                        <TableCell component="th" scope="row">
                                            <b>{row.project_name}</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{row.project_id}</b></TableCell>
                                        <TableCell align="right"><b>{row.age_group}</b></TableCell>
                                        <TableCell align="right"><b>{row.names}</b></TableCell>
                                        <TableCell align="right"><b>{row.done_status}</b></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} className="table-compact">
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Project Name</b></TableCell>
                                    <TableCell align="right"><b>Project ID</b></TableCell>
                                    <TableCell align="right"><b>Status</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsCompact.map((row) => (
                                    <TableRow
                                        key={row.project_name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => handleRowClick(row.project_id)}
                                    >
                                        <TableCell component="th" scope="row" className="truncate">
                                            <b>{row.project_name.length > 64 ? row.project_name.substring(0, 64) + '...' : row.project_name}</b>
                                        </TableCell>
                                        <TableCell align="right"><b>{row.project_id}</b></TableCell>
                                        <TableCell align="right"><b>{row.done_status}</b></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : <Typography>You do not have a valid schedule: {message}</Typography>
    );
}

export default JudgeSchedule;