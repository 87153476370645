import React, {useEffect, useRef, useState} from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import {login} from '../../api/auth';
import {getUserData, getPersonData, loadUserDataComplete} from "../../api/api";
import useAuth from '../../hooks/useAuth';
import {useNavigate, useLocation} from "react-router-dom";
import {useError} from "../../context/ErrorContext";

const LoginForm = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated, isAuthenticated } = useAuth();
  const { showMessage } = useError();
  const hasShownMessage = useRef(false);

  useEffect(() => {
    if(isAuthenticated){
      navigate('/auth/dashboard');
    }
  }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (!hasShownMessage.current && location.state?.message && location.state?.severity) {
          showMessage(location.state.message, location.state.severity);
          hasShownMessage.current = true;
        }
    }, [location.state, showMessage]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await login({ username, password });
      // console.log(response)
      const {access_token, expires_at} = response.data;
      localStorage.setItem('token', access_token);
      localStorage.setItem('tokenExpiry', expires_at);

      console.log('Login successful');

      setIsAuthenticated(true);
      await loadUserDataComplete(props.setUserData)

      navigate('/auth/dashboard', { state: { message: 'Login successful!', severity: 'success' } });
    } catch (error) {
      console.error('Login failed:', error);
      showMessage('Login failed', 'error');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
        <Box>
          {/*<Typography>Not signed up?</Typography>*/}
          {/*<Button*/}
          {/*  onClick={() => navigate('/signup')}*/}
          {/*  fullWidth*/}
          {/*  variant="contained"*/}
          {/*  sx={{ mt: 3, mb: 2 }}*/}
          {/*>*/}
          {/*  Sign Up*/}
          {/*</Button>*/}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
