import React, { useState } from 'react';
import { Box, Typography, IconButton, Drawer, Divider, Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import TemplateManagementButton from './TemplateManagementButton';
import RightAdminSidebar from '../../sidebar/RightAdminSidebar';
import LeftSidebar from '../../sidebar/LeftSidebar';
import '../../../styles/dashboard.css';
import {createRubricTemplate} from "../../../api/api";
import Title from "../../reusable/Title";

const TemplateManagementView = (props) => {
    const navigate = useNavigate();
    const [loadedData, setLoadedData] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [templates, setTemplates] = useState(props.userData.adminData.templates);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    let userName = props.userData?.personData?.first_name || props.userData?.username || "?";

    const removeAllDefaults = () => {
        const updatedTemplates = templates.map(template => ({
            ...template,
            is_default: 0
        }));
        setTemplates(updatedTemplates);
    };

    const deleteEntry = async (id) => {
        const updatedTemplates = templates.filter(template => template.id !== id);
        setTemplates(updatedTemplates);
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCreate = async () => {
        if(name === "" || description === "") {
            return;
        }
        const result = await createRubricTemplate(name, description)
        if (result === null || result === undefined) {
            console.error("Error creating template");
            return;
        }
        setTemplates([...templates, result]);
        handleDialogClose();
    };

    return (
        <div className="container">
            <IconButton
                className="dashboard-drawer"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{position: 'absolute', top: 10, left: 10}}
            >
                <div className="dashboard-drawer">
                    <MenuIcon/>
                </div>
            </IconButton>
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{width: 200, margin: 1, marginRight: 4}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData}/>
                    <Divider sx={{my: 2}}/>
                    <LeftSidebar name={userName} setUserData={props.setUserData}/>
                </Box>
            </Drawer>

            <div className="left-sidebar">
                <LeftSidebar name={userName} setUserData={props.setUserData}/>
            </div>

            <div className="main-content">
                <Title/>
                <Box>
                    <div style={{alignItems: "left", marginBottom: "20px"}}>
                        <Typography variant="h4">Template Management</Typography>
                        <Typography variant="body1" sx={{"fontSize": "15px"}}>Modify your region's rubrics prior to
                            their
                            instantiation.</Typography>
                    </div>

                    {/*<Typography variant="h5">Templates for your region:</Typography>*/}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        {templates
                            .sort((a, b) => b.is_default - a.is_default)
                            .map((template, index) => (
                                <Box key={index} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <TemplateManagementButton
                                        data={template}
                                        removeAllDefaults={removeAllDefaults}
                                        setTemplates={setTemplates}
                                        deleteEntry={deleteEntry}
                                    />
                                </Box>
                            ))}
                        <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                            Add Template
                        </Button>
                    </Box>

                </Box>

                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Create New Template</DialogTitle>
                    <DialogContent>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography>Name:</Typography>
                                <TextField
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    inputProps={{maxLength: 255}}
                                    sx={{backgroundColor: '#d9d9d9', input: {color: 'black'}}}
                                />
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography>Description:</Typography>
                                <TextField
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    sx={{backgroundColor: '#d9d9d9', input: {color: 'black'}}}
                                />
                            </Box>
                            <Button sx={{
                                backgroundColor: '#197319',
                                color: 'white',
                                '&:hover': {backgroundColor: '#197319'}
                            }} onClick={handleCreate}>
                                Create
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
            <div className="right-sidebar">
                <RightAdminSidebar userData={props.userData} setUserData={props.setUserData}/>
            </div>
        </div>
    );
};

export default TemplateManagementView;